import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavService } from '../../../../services/nav.service';
import {DefaultlangService} from './defaultlang.service'
@Component({
  selector: 'app-languages',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss']
})
export class LanguagesComponent implements OnInit {

  public language: boolean = false;

  public languages: any[] = [{
    language: 'English',
    code: 'en',
    icon: 'us'
  },
  {
    language: 'Arabic',
    code: 'ar',
    icon: 'eg'
  }
]
  public selectedLanguage: any;
 public defaultLan: any;

  constructor(private DefaultlangService:DefaultlangService,public navServices: NavService, public translate: TranslateService) {
    this.selectedLanguage = JSON.parse(localStorage.getItem('selectedLanguage') as any) || this.languages[0];
    this.changeLanguage(this.selectedLanguage); 
   }
  // 
  ngOnInit() {
    
  }

  changeLanguage(lang) {
    if(lang.code=="ar"){
      document.getElementsByTagName('html')[0].setAttribute('dir', 'rtl');
    }
    else{
      document.getElementsByTagName('html')[0].setAttribute('dir', 'ltr');
    }
    this.translate.use(lang.code);
    this.selectedLanguage = lang;
    localStorage.setItem('selectedLanguage', JSON.stringify(lang)); 
    this.defaultLan=(JSON.parse(localStorage.getItem('selectedLanguage') as any)?.code || 'en')
    this.DefaultlangService.setLangCode((JSON.parse(localStorage.getItem('selectedLanguage') as any)?.code || 'en'))
  }

}
